.ag-profile-grid {
  &__grid {
    .dx-datagrid {
      .dx-datagrid-header-panel {
        padding: 0;
        border: none;
      }
      .dx-datagrid-headers {
        border: none;
        background-color: #fff;
        font-weight: 700;
        color: #505d69;
      }
      .dx-datagrid-rowsview {
        border: none;
      }

      .dx-command-edit {
        padding-left: 0;
        padding-right: 0;

        .dx-link-icon {
          width: 24px !important;
        }
      }
      td {
        &:first-of-type {
          padding-left: 0;
        }
      }
    }
  }

  &__text-with-icons {
    display: flex;
    align-items: flex-start;
    line-height: 20px;
    gap: 4px;
    span {
      padding-top: 2px;
    }
  }

  &__toolbar-button {
    .dx-button {
      border: none;

      .dx-button-content {
        width: 24px;
        padding: 0;
      }
    }
  }
}
