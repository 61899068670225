a {
  outline-color: var(--color-base-accent-alpha-40);
  outline-offset: 2px;
}

:root {
  --color-base-bg: #ffffff;
  --color-base-accent: #289fe7;
  --color-base-accent-alpha-40: hsl(from var(--color-accent) h s l, 0.4);
  --color-base-text: #333333;
  --color-base-text-alpha-50: hsl(from var(--color-base-text) h s l, 0.5);
  --color-base-inverted-text: #ffffff;
  --color-base-border: #dddddd;
  --color-base-bg-darker-4: hsl(from var(--color-base-bg) h s calc(l - 4));
  --color-base-bg-darker-5: hsl(from var(--color-base-bg) h s calc(l - 5));
  --color-base-bg-darker-9: hsl(from var(--color-base-bg) h s calc(l - 9));
  --color-base-bg-darker-8: hsl(from var(--color-base-bg) h s calc(l - 8));
  --color-base-bg-darker-12: hsl(from var(--color-base-bg) h s calc(l - 12));
  --color-base-bg-darker-17: hsl(from var(--color-base-bg) h s calc(l - 17));
  --color-base-bg-darker-23: hsl(from var(--color-base-bg) h s calc(l - 23));
  --color-base-bg-darker-24: hsl(from var(--color-base-bg) h s calc(l - 24));
  --color-base-bg-lighter-35: hsl(from var(--color-base-bg) h s calc(l + 35));
  --color-base-accent-darker-4: hsl(from var(--color-base-accent) h s calc(l - 4));
  --color-base-accent-darker-5: hsl(from var(--color-base-accent) h s calc(l - 5));
  --color-base-accent-darker-9: hsl(from var(--color-base-accent) h s calc(l - 9));
  --color-base-accent-darker-8: hsl(from var(--color-base-accent) h s calc(l - 8));
  --color-base-accent-darker-12: hsl(from var(--color-base-accent) h s calc(l - 12));
  --color-base-accent-darker-17: hsl(from var(--color-base-accent) h s calc(l - 17));
  --color-base-accent-darker-23: hsl(from var(--color-base-accent) h s calc(l - 23));
  --color-base-accent-darker-24: hsl(from var(--color-base-accent) h s calc(l - 24));
  --color-base-accent-lighter-35: hsl(from var(--color-base-accent) h s calc(l + 35));
  --color-base-danger: #d9534f;
}

.dx-button-mode-contained.dx-button-normal {
  background-color: var(--color-base-bg);
  border-color: var(--color-base-border);
  color: var(--color-base-text);
}

.dx-button-mode-contained.dx-button-normal .dx-icon {
  color: var(--color-base-text);
}

.dx-button-mode-contained.dx-button-normal.dx-state-selected {
  background-color: var(--color-base-bg-darker-17);
}

.dx-button-mode-contained.dx-button-normal.dx-state-hover {
  background-color: var(--color-base-bg-darker-4);
}

.dx-button-mode-contained.dx-button-normal.dx-state-focused {
  background-color: var(--color-base-bg-darker-8);
}

.dx-button-mode-contained.dx-button-normal.dx-state-active {
  background-color: var(--color-base-bg-darker-24);
  color: var(--color-base-text);
}

.dx-button-mode-contained.dx-button-default {
  background-color: var(--color-base-accent);
  border-color: transparent;
  color: var(--color-base-inverted-text);
}

.dx-button-mode-contained.dx-button-default .dx-icon {
  color: var(--color-base-inverted-text);
}

.dx-button-mode-contained.dx-button-default.dx-state-selected {
  background-color: var(--color-base-accent-darker-17);
}

.dx-button-mode-contained.dx-button-default.dx-state-hover {
  background-color: var(--color-base-accent-darker-9);
}

.dx-button-mode-contained.dx-button-default.dx-state-focused {
  background-color: var(--color-base-accent-darker-12);
}

.dx-button-mode-contained.dx-button-default.dx-state-active {
  background-color: var(--color-base-accent-darker-23);
  color: var(--color-base-inverted-text);
}

.dx-texteditor.dx-editor-outlined {
  background: var(--color-base-bg);
  border-color: var(--color-base-border);
}

.dx-texteditor.dx-editor-underlined {
  background: transparent;
}

.dx-texteditor.dx-state-readonly.dx-editor-filled {
  border-color: transparent;
}

.dx-texteditor.dx-state-hover {
  border-color: var(--color-base-accent-alpha-40);
}

.dx-texteditor.dx-state-hover.dx-editor-filled {
  border-color: transparent;
}

.dx-texteditor.dx-state-hover.dx-invalid.dx-editor-filled, .dx-texteditor.dx-state-hover.dx-invalid.dx-editor-outlined {
  border-color: var(--color-base-danger);
}

.dx-texteditor.dx-state-focused .dx-texteditor-label {
  color: var(--color-base-accent);
}

.dx-texteditor.dx-state-focused.dx-invalid .dx-texteditor-label {
  color: var(--color-base-danger);
}

.dx-texteditor.dx-texteditor-label-outside .dx-texteditor-label {
  color: var(--color-base-text);
}

.dx-texteditor.dx-texteditor-label-outside.dx-invalid .dx-texteditor-label {
  color: var(--color-base-danger);
}

.dx-texteditor.dx-state-focused.dx-editor-filled, .dx-texteditor.dx-state-focused.dx-editor-underlined, .dx-texteditor.dx-state-focused.dx-editor-outlined, .dx-texteditor.dx-state-active.dx-editor-filled, .dx-texteditor.dx-state-active.dx-editor-underlined, .dx-texteditor.dx-state-active.dx-editor-outlined {
  border-color: var(--color-base-accent);
}

.dx-texteditor.dx-state-focused.dx-editor-filled, .dx-texteditor.dx-state-active.dx-editor-filled {
  background-color: transparent;
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-readonly .dx-label, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-readonly .dx-label {
  border-color: var(--color-base-border);
  border-bottom-color: var(--color-base-border);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-readonly .dx-label-before, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-readonly .dx-label-before {
  border-color: var(--color-base-border);
  border-bottom-color: var(--color-base-border);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-readonly .dx-label-after, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-readonly .dx-label-after {
  border-color: var(--color-base-border);
  border-bottom-color: var(--color-base-border);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-disabled .dx-label, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-disabled .dx-label {
  border-color: var(--color-base-text-alpha-50);
  border-bottom-color: var(--color-base-text-alpha-50);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-disabled .dx-label-before, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-disabled .dx-label-before {
  border-color: var(--color-base-text-alpha-50);
  border-bottom-color: var(--color-base-text-alpha-50);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-disabled .dx-label-after, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-disabled .dx-label-after {
  border-color: var(--color-base-text-alpha-50);
  border-bottom-color: var(--color-base-text-alpha-50);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-invalid .dx-label, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid .dx-label {
  border-color: var(--color-base-danger);
  border-bottom-color: var(--color-base-danger);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-invalid .dx-label-before, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid .dx-label-before {
  border-color: var(--color-base-danger);
  border-bottom-color: var(--color-base-danger);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-invalid .dx-label-after, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid .dx-label-after {
  border-color: var(--color-base-danger);
  border-bottom-color: var(--color-base-danger);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-hover .dx-label, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-hover .dx-label {
  border-color: var(--color-base-accent-alpha-40);
  border-bottom-color: var(--color-base-accent-alpha-40);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-hover .dx-label-before, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-hover .dx-label-before {
  border-color: var(--color-base-accent-alpha-40);
  border-bottom-color: var(--color-base-accent-alpha-40);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-hover .dx-label-after, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-hover .dx-label-after {
  border-color: var(--color-base-accent-alpha-40);
  border-bottom-color: var(--color-base-accent-alpha-40);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label {
  border-color: var(--color-base-accent);
  border-bottom-color: var(--color-base-accent);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label-before, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label-before {
  border-color: var(--color-base-accent);
  border-bottom-color: var(--color-base-accent);
}

.dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label-after, .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label-after {
  border-color: var(--color-base-accent);
  border-bottom-color: var(--color-base-accent);
}

.dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid var(--color-base-border);
}

.dx-popup-wrapper .dx-state-focused.dx-overlay-content {
  border: 1px solid var(--color-base-accent);
}

.dx-scrollable-scrollbar {
  border-radius: 8px;
}

.dx-scrollable-wrapper .dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll-content {
  background-color: #289fe7;
}
.dx-scrollable-wrapper .dx-scrollable-scroll-content {
  background-color: #289fe7;
  border-radius: 8px;
}

.dx-layout-manager .dx-label-h-align .dx-field-item-label {
  white-space: normal;
}
.dx-layout-manager .dx-label-h-align .dx-field-item-label .dx-field-item-label-content {
  max-width: 100%;
}

.dx-field-item-required .dx-label > span::after {
  content: " *";
}

.dx-accordion-item {
  border-left-color: #ddd;
  border-right-color: #ddd;
}

.dx-daterangebox.dx-invalid .dx-editor-outlined.dx-state-focused.dx-widget::before,
.dx-daterangebox.dx-invalid .dx-editor-outlined.dx-state-active.dx-widget::before,
.dx-daterangebox.dx-invalid .dx-editor-outlined.dx-state-hover.dx-widget::before,
.dx-daterangebox .dx-editor-outlined.dx-state-focused.dx-widget::before,
.dx-daterangebox .dx-editor-outlined.dx-state-active.dx-widget::before,
.dx-daterangebox .dx-editor-outlined.dx-state-hover.dx-widget::before {
  left: -3px;
  bottom: 4px;
}

.dx-select-checkbox.dx-checkbox.dx-checkbox-checked .dx-checkbox-icon, .dx-select-checkbox.dx-checkbox.dx-checkbox-indeterminate .dx-checkbox-icon {
  font-size: 12px !important;
  font: 16px/1em DXIcons;
  color: #fff !important;
  text-align: center;
  background-color: #337ab7;
}
.dx-select-checkbox.dx-checkbox.dx-checkbox-checked .dx-checkbox-icon::before, .dx-select-checkbox.dx-checkbox.dx-checkbox-indeterminate .dx-checkbox-icon::before {
  content: "\f074";
  position: relative;
  display: block;
  width: 1em;
  top: 50%;
  height: unset;
  margin-top: -0.5em;
  left: 50%;
  margin-left: -0.5em;
  background-color: unset;
}
.dx-select-checkbox.dx-checkbox.dx-checkbox-checked .dx-checkbox-icon::before {
  content: "\f005";
}

.ag-switch-with-label.dx-field-item {
  align-items: center;
  padding-right: 0 !important;
}
.ag-switch-with-label.dx-field-item .dx-field-item-label.dx-field-item-label-location-left {
  flex-grow: 1;
}
.ag-switch-with-label.dx-field-item .dx-field-item-label.dx-field-item-label-location-left .dx-field-item-label-content {
  min-width: 100%;
  font-size: 12px;
}
.ag-switch-with-label.dx-field-item .dx-field-item-label.dx-field-item-label-location-left .dx-field-item-label-content .dx-field-item-label-text {
  color: #999;
}
.ag-switch-with-label.dx-field-item .dx-field-item-content.dx-field-item-content-location-right {
  width: 44px;
  flex-grow: initial;
}

.ag-select-box-drop-down .dx-popup-normal {
  min-width: min-content;
}
.ag-select-box-drop-down .dx-popup-normal .dx-item.dx-list-item {
  width: auto;
}
.ag-select-box-drop-down .dx-popup-normal .dx-item.dx-list-item .dx-item-content.dx-list-item-content {
  width: min-content;
}

.ag-summary-table {
  --ag-summary-table-min-width: 200px;
  min-width: var(--ag-summary-table-min-width);
}
.ag-summary-table .dx-toolbar-items-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  min-height: 36px;
  height: auto;
}
.ag-summary-table .dx-toolbar-items-container .dx-toolbar-before,
.ag-summary-table .dx-toolbar-items-container .dx-toolbar-center,
.ag-summary-table .dx-toolbar-items-container .dx-toolbar-after {
  position: static;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.ag-profile-grid__grid .dx-datagrid .dx-datagrid-header-panel {
  padding: 0;
  border: none;
}
.ag-profile-grid__grid .dx-datagrid .dx-datagrid-headers {
  border: none;
  background-color: #fff;
  font-weight: 700;
  color: #505d69;
}
.ag-profile-grid__grid .dx-datagrid .dx-datagrid-rowsview {
  border: none;
}
.ag-profile-grid__grid .dx-datagrid .dx-command-edit {
  padding-left: 0;
  padding-right: 0;
}
.ag-profile-grid__grid .dx-datagrid .dx-command-edit .dx-link-icon {
  width: 24px !important;
}
.ag-profile-grid__grid .dx-datagrid td:first-of-type {
  padding-left: 0;
}
.ag-profile-grid__text-with-icons {
  display: flex;
  align-items: flex-start;
  line-height: 20px;
  gap: 4px;
}
.ag-profile-grid__text-with-icons span {
  padding-top: 2px;
}
.ag-profile-grid__toolbar-button .dx-button {
  border: none;
}
.ag-profile-grid__toolbar-button .dx-button .dx-button-content {
  width: 24px;
  padding: 0;
}