@mixin ag-button-styling-variant(
  $ag-background-color,
  $ag-border-color,
  $ag-text-color,
  $ag-hover-background-color,
  $ag-focused-background-color,
  $ag-active-background-color,
  $ag-active-text-color,
  $ag-icon-color,
  $ag-selected-background-color
) {
  background-color: $ag-background-color;
  border-color: $ag-border-color;
  color: $ag-text-color;

  .dx-icon {
    color: $ag-icon-color;
  }

  &.dx-state-selected {
    background-color: $ag-selected-background-color;
  }

  &.dx-state-hover {
    background-color: $ag-hover-background-color;
  }

  &.dx-state-focused {
    background-color: $ag-focused-background-color;
  }

  &.dx-state-active {
    background-color: $ag-active-background-color;
    color: $ag-active-text-color;
  }
}

.dx-button-mode-contained {
  &.dx-button-normal {
    @include ag-button-styling-variant(
      var(--color-base-bg),
      var(--color-base-border),
      var(--color-base-text),
      var(--color-base-bg-darker-4),
      var(--color-base-bg-darker-8),
      var(--color-base-bg-darker-24),
      var(--color-base-text),
      var(--color-base-text),
      var(--color-base-bg-darker-17)
    );
  }

  &.dx-button-default {
    @include ag-button-styling-variant(
      var(--color-base-accent),
      transparent,
      var(--color-base-inverted-text),
      var(--color-base-accent-darker-9),
      var(--color-base-accent-darker-12),
      var(--color-base-accent-darker-23),
      var(--color-base-inverted-text),
      var(--color-base-inverted-text),
      var(--color-base-accent-darker-17)
    );
  }
}
