.dx-popup-wrapper {
  & > .dx-overlay-content {
    border: 1px solid var(--color-base-border);
  }

  .dx-state-focused {
    &.dx-overlay-content {
      border: 1px solid var(--color-base-accent);
    }
  }
}
