$default-vars: (
  color-base-bg: #ffffff,
  color-base-accent: #289fe7,
  color-base-danger: #d9534f,
  color-base-text: #333333,
  color-base-inverted-text: #ffffff,
  color-base-border: #dddddd
);

@mixin set-nested-css-vars($vars) {
  // Merge user values with defaults
  $vars: map-merge($default-vars, $vars);

  --color-base-bg: #{map-get($vars, color-base-bg)};
  --color-base-accent: #{map-get($vars, color-base-accent)};
  --color-base-accent-alpha-40: hsl(from var(--color-accent) h s l, 0.4);
  --color-base-text: #{map-get($vars, color-base-text)};
  --color-base-text-alpha-50: hsl(from var(--color-base-text) h s l, 0.5);
  --color-base-inverted-text: #{map-get($vars, color-base-inverted-text)};
  --color-base-border: #{map-get($vars, color-base-border)};
  --color-base-bg-darker-4: hsl(from var(--color-base-bg) h s calc(l - 4));
  --color-base-bg-darker-5: hsl(from var(--color-base-bg) h s calc(l - 5));
  --color-base-bg-darker-9: hsl(from var(--color-base-bg) h s calc(l - 9));
  --color-base-bg-darker-8: hsl(from var(--color-base-bg) h s calc(l - 8));
  --color-base-bg-darker-12: hsl(from var(--color-base-bg) h s calc(l - 12));
  --color-base-bg-darker-17: hsl(from var(--color-base-bg) h s calc(l - 17));
  --color-base-bg-darker-23: hsl(from var(--color-base-bg) h s calc(l - 23));
  --color-base-bg-darker-24: hsl(from var(--color-base-bg) h s calc(l - 24));
  --color-base-bg-lighter-35: hsl(from var(--color-base-bg) h s calc(l + 35));
  --color-base-accent-darker-4: hsl(from var(--color-base-accent) h s calc(l - 4));
  --color-base-accent-darker-5: hsl(from var(--color-base-accent) h s calc(l - 5));
  --color-base-accent-darker-9: hsl(from var(--color-base-accent) h s calc(l - 9));
  --color-base-accent-darker-8: hsl(from var(--color-base-accent) h s calc(l - 8));
  --color-base-accent-darker-12: hsl(from var(--color-base-accent) h s calc(l - 12));
  --color-base-accent-darker-17: hsl(from var(--color-base-accent) h s calc(l - 17));
  --color-base-accent-darker-23: hsl(from var(--color-base-accent) h s calc(l - 23));
  --color-base-accent-darker-24: hsl(from var(--color-base-accent) h s calc(l - 24));
  --color-base-accent-lighter-35: hsl(from var(--color-base-accent) h s calc(l + 35));
  --color-base-danger: #{map-get($vars, color-base-danger)};
}

a {
  outline-color: var(--color-base-accent-alpha-40);
  outline-offset: 2px;
}
