@mixin ag-state-border-color($borderColor, $border-bottom-color) {
  .dx-label {
    border-color: $borderColor;
    border-bottom-color: $border-bottom-color;
  }

  .dx-label-before {
    border-color: $borderColor;
    border-bottom-color: $border-bottom-color;
  }

  .dx-label-after {
    border-color: $borderColor;
    border-bottom-color: $border-bottom-color;
  }
}

.dx-texteditor {
  &.dx-editor-outlined {
    background: var(--color-base-bg);
    border-color: var(--color-base-border);
  }

  &.dx-editor-underlined {
    background: transparent;
  }

  &.dx-editor-filled {
    // background: $texteditor-filled-bg;
  }

  &.dx-state-readonly {
    &.dx-editor-filled {
      border-color: transparent;
      // background-color: color.change($texteditor-filled-bg, $alpha: 0.03);

      &.dx-state-focused {
        // background-color: color.change($texteditor-filled-bg, $alpha: 0.03);
      }

      &.dx-state-hover {
        // background-color: color.change($texteditor-hover-border-color, $alpha: 0.07);
      }
    }
  }

  &.dx-state-hover {
    border-color: var(--color-base-accent-alpha-40);

    &.dx-editor-filled {
      border-color: transparent;
      // background-color: color.change($texteditor-hover-border-color, $alpha: 0.1);
    }

    &.dx-invalid {
      &.dx-editor-filled,
      &.dx-editor-outlined {
        border-color: var(--color-base-danger);
      }
    }
  }

  &.dx-state-focused {
    .dx-texteditor-label {
      color: var(--color-base-accent);
    }

    &.dx-invalid {
      .dx-texteditor-label {
        color: var(--color-base-danger);
      }
    }
  }

  &.dx-texteditor-label-outside {
    .dx-texteditor-label {
      color: var(--color-base-text);
    }

    &.dx-invalid {
      .dx-texteditor-label {
        color: var(--color-base-danger);
      }
    }
  }

  &.dx-state-focused,
  &.dx-state-active {
    &.dx-editor-filled,
    &.dx-editor-underlined,
    &.dx-editor-outlined {
      border-color: var(--color-base-accent);
    }

    &.dx-editor-filled {
      background-color: transparent;
    }
  }
}
.dx-editor-outlined {
  &.dx-texteditor-with-label,
  &.dx-texteditor-with-floating-label {
    &.dx-state-readonly {
      @include ag-state-border-color(var(--color-base-border), var(--color-base-border));
    }

    &.dx-state-disabled {
      @include ag-state-border-color(var(--color-base-text-alpha-50), var(--color-base-text-alpha-50));
    }

    &.dx-invalid {
      @include ag-state-border-color(var(--color-base-danger), var(--color-base-danger));

      &.dx-state-hover,
      &.dx-state-focused {
        // @include ag-state-border-color(
        //   $texteditor-invalid-focused-border-color,
        //   $texteditor-invalid-focused-border-color
        // );
      }
    }

    &.dx-state-hover {
      @include ag-state-border-color(var(--color-base-accent-alpha-40), var(--color-base-accent-alpha-40));
    }

    &.dx-state-focused {
      @include ag-state-border-color(var(--color-base-accent), var(--color-base-accent));
    }
  }
}
