@use "dx-button";
@use "dx-texteditor-colors";
@use "dx-popup-colors";

.dx-scrollable-scrollbar {
  border-radius: 8px;
}

.dx-scrollable-wrapper {
  .dx-scrollbar-hoverable.dx-scrollable-scrollbar-active {
    .dx-scrollable-scroll-content {
      background-color: #289fe7;
    }
  }
  .dx-scrollable-scroll-content {
    background-color: #289fe7;
    border-radius: 8px;
  }
}

.dx-layout-manager {
  .dx-label-h-align {
    .dx-field-item-label {
      white-space: normal;

      .dx-field-item-label-content {
        max-width: 100%;
      }
    }
  }
}

.dx-field-item-required .dx-label > span::after {
  content: " *";
  // color: #f44336;
}

.dx-accordion-item {
  border-left-color: #ddd;
  border-right-color: #ddd;
}

.dx-daterangebox.dx-invalid .dx-editor-outlined.dx-state-focused,
.dx-daterangebox.dx-invalid .dx-editor-outlined.dx-state-active,
.dx-daterangebox.dx-invalid .dx-editor-outlined.dx-state-hover,
.dx-daterangebox .dx-editor-outlined.dx-state-focused,
.dx-daterangebox .dx-editor-outlined.dx-state-active,
.dx-daterangebox .dx-editor-outlined.dx-state-hover {
  &.dx-widget {
    &::before {
      left: -3px;
      bottom: 4px;
    }
  }
}

.dx-select-checkbox.dx-checkbox {
  &.dx-checkbox-checked,
  &.dx-checkbox-indeterminate {
    .dx-checkbox-icon {
      font-size: 12px !important;
      font: 16px/1em DXIcons;
      color: #fff !important;
      text-align: center;
      background-color: #337ab7;

      &::before {
        content: "\f074";
        position: relative;
        display: block;
        width: 1em;
        top: 50%;
        height: unset;
        margin-top: -0.5em;
        left: 50%;
        margin-left: -0.5em;
        background-color: unset;
      }
    }
  }

  &.dx-checkbox-checked {
    .dx-checkbox-icon {
      &::before {
        content: "\f005";
      }
    }
  }
}
