@import "ag-theme.scss";

:root {
  // --color-base-accent: #289fe7;
  // --color-base-accent-darker-5: hsl(from var(--color-base-accent) h s calc(l - 5));
  // --color-base-accent-darker-9: hsl(from var(--color-base-accent) h s calc(l - 9));
  // --color-base-accent-lighter-35: hsl(from var(--color-base-accent) h s calc(l + 35));
  // --color-base-danger: #d9534f;
  @include set-nested-css-vars(());
}
